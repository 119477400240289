import InboxIcon from '@material-ui/icons/InboxOutlined';
import TrackerIcon from '@material-ui/icons/ExploreOutlined';
import WorkflowIcon from '@material-ui/icons/PlayArrow';
import {
	AdvancedMenuIcon,
	DashboardIconFill,
	DashboardIconLine,
	DeviceIcon,
	DeviceIconFill,
	DeviceIconLine,
	FormIcon,
	FormIconFill,
	FormIconLine,
	HomeMenuIcon,
	LicenseGroupIcon,
	LogIconFill,
	LogIconLine,
	LogsIcon,
	AppsIcon,
	ReportsIcon,
	// ReportsPageIcon,
	RolesIcon,
	RolesIconFill,
	RolesIconLine,
	ReportsIconLine,
	ReportsIconFill,
	TenantIconFill,
	TenantIconLine,
	UserIcon,
	UserIconFill,
	UserIconLine,
	WorkflowIconFill,
	WorkflowIconLine,
	JobQueueIcon,
	JobsIcon,
	LicenseIconLine,
	LicenseIconFill
} from 'app/components/Icons';
import { licenseGroupPlanDesignCheck } from 'app/utils/tenant-plan';

type NavigationConfig = {
	id: string;
	Icon: React.ReactNode;
	LineIcon: React.ReactNode;
	FillIcon: React.ReactNode;
	section: 'admin' | 'manager';
	children?: NavigationConfig[];
} & (
	| {
			title: React.ReactNode;
			translate?: never;
	  }
	| {
			title?: never;
			translate: string; // translates in `common.json` (see `FuseNavVerticalItem.js` and `FuseNavHorizontalItem.js`)
	  }
) &
	(
		| {
				type: 'item';
				url: string;
		  }
		| {
				type: 'collapse' | 'group';
				url?: string;
		  }
	);

const navigationConfigStarter: NavigationConfig[] = [
	{
		id: 'workflows',
		translate: 'Workflows',
		type: 'item',
		Icon: WorkflowIcon,
		LineIcon: WorkflowIconLine,
		FillIcon: WorkflowIconFill,
		url: '/admin/workflows',
		section: 'admin'
	},
	{
		id: 'dashboard',
		translate: 'Dashboard',
		type: 'item',
		Icon: ReportsIcon,
		LineIcon: DashboardIconLine,
		FillIcon: DashboardIconFill,
		url: '/admin/dashboard',
		section: 'admin'
	},
	{
		id: 'devices',
		translate: 'Devices',
		type: 'item',
		Icon: DeviceIcon,
		LineIcon: DeviceIconLine,
		FillIcon: DeviceIconFill,
		url: '/admin/devices',
		section: 'admin'
	},
	{
		id: 'users',
		translate: 'Users',
		type: 'item',
		Icon: UserIcon,
		LineIcon: UserIconLine,
		FillIcon: UserIconFill,
		url: '/admin/users',
		section: 'admin'
	},
	{
		id: 'forms',
		translate: 'Forms',
		type: 'item',
		Icon: FormIcon,
		LineIcon: FormIconLine,
		FillIcon: FormIconFill,
		url: '/admin/forms',
		section: 'admin'
	},
	{
		id: 'logs',
		translate: 'Logs',
		type: 'item',
		Icon: LogsIcon,
		LineIcon: LogIconLine,
		FillIcon: LogIconFill,
		url: '/admin/logs',
		section: 'admin'
	}
	// {
	// 	id: 'input',
	// 	translate: 'Licenses',
	// 	type: 'item',
	// 	Icon: LogsIcon,
	// 	LineIcon: LicenseIconLine,
	// 	FillIcon: LicenseIconFill,
	// 	url: '/admin/input',
	// 	section: 'admin'
	// }
	// {
	// 	id: 'reports',
	// 	translate: 'Reports',
	// 	type: 'item',
	// 	Icon: ReportIcon,
	// 	url: '/admin/reports',
	// section: 'admin'
	// },
];

const navigationConfigEnterprise: NavigationConfig[] = [
	{
		id: 'home',
		translate: 'Home',
		type: 'collapse',
		Icon: HomeMenuIcon,
		LineIcon: HomeMenuIcon,
		FillIcon: HomeMenuIcon,
		url: '/admin/home',
		section: 'admin',
		children: [
			{
				id: 'inbox',
				translate: 'Inbox',
				type: 'item',
				Icon: InboxIcon,
				LineIcon: InboxIcon,
				FillIcon: InboxIcon,
				url: '/admin/home/inbox',
				section: 'admin'
			},
			{
				id: 'jobs',
				translate: 'Jobs',
				type: 'item',
				Icon: JobsIcon,
				LineIcon: JobsIcon,
				FillIcon: JobsIcon,
				url: '/admin/home/jobs',
				section: 'admin'
			},
			{
				id: 'job-queue',
				translate: 'Job Queues',
				type: 'item',
				Icon: JobQueueIcon,
				LineIcon: JobQueueIcon,
				FillIcon: JobQueueIcon,
				url: '/admin/home/job-queue',
				section: 'admin'
			},
			{
				id: 'tracker',
				translate: 'Tracker',
				type: 'item',
				Icon: TrackerIcon,
				LineIcon: TrackerIcon,
				FillIcon: TrackerIcon,
				url: '/admin/home/tracker',
				section: 'admin'
			}
		]
	},
	{
		id: 'dashboard',
		translate: 'Dashboard',
		type: 'item',
		Icon: ReportsIcon,
		LineIcon: DashboardIconLine,
		FillIcon: DashboardIconFill,
		url: '/admin/dashboard',
		section: 'admin'
	},
	...(process.env.REACT_APP_ENVIRONMENT === 'development'
		? [
				{
					id: 'apps',
					translate: 'Apps',
					type: 'item',
					Icon: AppsIcon,
					LineIcon: AppsIcon,
					FillIcon: AppsIcon,
					url: '/admin/apps',
					section: 'admin'
				}
		  ]
		: ([] as any)),
	{
		id: 'workflows',
		translate: 'Workflows',
		type: 'item',
		Icon: WorkflowIcon,
		LineIcon: WorkflowIconLine,
		FillIcon: WorkflowIconFill,
		url: '/admin/workflows',
		section: 'admin'
	},
	// {
	// 	id: 'reports',
	// 	translate: 'Reports',
	// 	type: 'item',
	// 	Icon: ReportsPageIcon,
	// 	LineIcon: ReportsPageIcon,
	// 	FillIcon: ReportsPageIcon,
	// 	url: '/admin/reports',
	// 	section: 'admin'
	// },
	{
		id: 'advanced',
		translate: 'Advanced',
		type: 'collapse',
		Icon: AdvancedMenuIcon,
		LineIcon: AdvancedMenuIcon,
		FillIcon: AdvancedMenuIcon,
		section: 'admin',
		children: [
			{
				id: 'devices',
				translate: 'Devices',
				type: 'item',
				Icon: DeviceIcon,
				LineIcon: DeviceIconLine,
				FillIcon: DeviceIconFill,
				url: '/admin/devices',
				section: 'admin'
			},
			{
				id: 'users',
				translate: 'Users',
				type: 'item',
				Icon: UserIcon,
				LineIcon: UserIconLine,
				FillIcon: UserIconFill,
				url: '/admin/users',
				section: 'admin'
			},
			{
				id: 'forms',
				translate: 'Forms',
				type: 'item',
				Icon: FormIcon,
				LineIcon: FormIconLine,
				FillIcon: FormIconFill,
				url: '/admin/forms',
				section: 'admin'
			},
			{
				id: 'logs',
				translate: 'Logs',
				type: 'item',
				Icon: LogsIcon,
				LineIcon: LogIconLine,
				FillIcon: LogIconFill,
				url: '/admin/logs',
				section: 'admin'
			},
			{
				id: 'roles',
				translate: 'Roles',
				type: 'item',
				Icon: RolesIcon,
				LineIcon: RolesIconLine,
				FillIcon: RolesIconFill,
				url: '/admin/roles',
				section: 'admin'
			}
			// {
			// 	id: 'input',
			// 	translate: 'Licenses',
			// 	type: 'item',
			// 	Icon: RolesIcon,
			// 	LineIcon: LicenseIconLine,
			// 	FillIcon: LicenseIconFill,
			// 	url: '/admin/input',
			// 	section: 'admin'
			// }
		]
	}
];

const managerNavigationConfig: NavigationConfig[] = [
	{
		id: 'managed-license-groups',
		translate: 'Tenants',
		type: 'item',
		Icon: LicenseGroupIcon,
		LineIcon: TenantIconLine,
		FillIcon: TenantIconFill,
		url: '/manager/tenants',
		section: 'manager'
	},
	{
		id: 'manager-dashboard',
		translate: 'Dashboard',
		type: 'item',
		Icon: ReportsIcon,
		LineIcon: DashboardIconLine,
		FillIcon: DashboardIconFill,
		url: '/manager/dashboard',
		section: 'manager'
	},
	{
		id: 'manager-reports',
		translate: 'Reports',
		type: 'item',
		Icon: ReportsIcon,
		LineIcon: ReportsIconLine,
		FillIcon: ReportsIconFill,
		url: '/manager/reports',
		section: 'manager'
	}
];

const adminNavigationConfig = licenseGroupPlanDesignCheck('stratus')
	? navigationConfigEnterprise
	: navigationConfigStarter;

const navigationConfig = [...adminNavigationConfig, ...managerNavigationConfig];

export default navigationConfig;
